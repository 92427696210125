import { FastlyImage } from '@components/common/image'
import { PARTNERS_IMAGE_PATH } from '@lib/constants/geo-lp'
import { t, Trans } from '@lingui/macro'

const AlumnisOutsideIndia = () => {
  return (
    <>
      <div className="flex flex-col items-center space-y-1 text-grey">
        <FastlyImage
          width={50}
          height={52}
          src={`${PARTNERS_IMAGE_PATH}/iit-roorkee.png`}
          alt={t`IIT Roorkee`}
        />
        <span className="text-sm">
          <Trans>IIT Roorkee</Trans>
        </span>
      </div>
      <div className="flex flex-col items-center space-y-1 text-grey">
        <FastlyImage
          width={50}
          height={52}
          src={`${PARTNERS_IMAGE_PATH}/iit-dhanbad.png`}
          alt={t`IIT Dhanbad`}
        />
        <span className="text-sm">
          <Trans>IIT Dhanbad</Trans>
        </span>
      </div>
    </>
  )
}

export default AlumnisOutsideIndia
